// App.js
// React App that uses the SelectLearnSystem component to select a Learn system from the mobile site,
// then redirect the incomming request to that site. We use this for the /authorization code leg of
// Alexa skill linking. Note: there are several unused components in the /src directory that were used during
// proto typing: Greeting & RedirectButton, and DownshiftMoviesWithAxios. TODO: Clean that up.

import React, {Component, useState} from 'react';
import './App.css';
import SelectLearnSystem from './SelectLearnSystem';
import logo from '../src/images/bb-corp-logo.png'

function App() { 
  /* Function components have no instances. You can't refere to this */

  let location = window.location;
  let searchstring = `${location.search}`; 
  
  function refreshPage() {
    window.location.reload(false);
  }
  return (
    <div className="App">
        <h1>
            <div role="banner">
                <img className="logo" src={logo} alt=""/>
            </div>
        </h1>
        <SelectLearnSystem querystring={searchstring}/><br/>
        {/* From https://upmostly.com/tutorials/how-to-refresh-a-page-or-component-in-react */}
    </div>
  );
}

export default App;
