/* eslint-disable react/jsx-no-comment-textnodes */
// File: SelectLearnSystem.js
// Purpose: Use the mbaas.mobile.edu search endpoint to dynamically display a list of Blackboard Learn(tm) systems.
// When one is selected redirect the GET request on to the REST /oauth2/authorizationcode endpoint on that system.

// Requisites - An Amazon API Gateway URL of the form:
// https://mopvlq1z1f.execute-api.us-east-1.amazonaws.com/dev/institutions?q=${learnSystem}`;
// Here's the Gateway URL for our first demo:
// https://oxi3wh3zrb.execute-api.us-east-1.amazonaws.com/dev/institutions?q=${learnSystem};
// that makes GET to the following to get a list of Learn systems to select from:
// `https://mbaas.mobile.medu.com/mbaas/api/v1/institutions/search?q=${learnSystem}`
// The Gateway's HTTP GET is CORS enabled.

// import axios from 'axios'; Don't use axios. It's built to make cross-domain requests difficult.
import React, {Component} from 'react';
import Downshift from 'downshift';

class SelectLearnSystem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      learnSystems: []
    };
    console.log(`constructor() props.querystring:${this.props.querystring}`)
    this.fetchLearnSystems = this.fetchLearnSystems.bind(this);
    this.inputOnChange = this.inputOnChange.bind(this);
    this.downshiftOnChange = this.downshiftOnChange.bind(this);

  }

  inputOnChange(event) {
    if (!event.target.value) {
      return;
    }
    console.log(`inputOnChange() querystring:${this.props.querystring}`);
    this.fetchLearnSystems(event.target.value);
  }

  downshiftOnChange(selectedLearnSystem) {
    const authcodeendpoint =  "/learn/api/public/v1/oauth2/authorizationcode";
    let myquery = `${this.props.querystring}`;
    console.log(`downshiftOnChange myquery:${myquery}`);
    console.log(`Linking...to https://${selectedLearnSystem.domain}${authcodeendpoint}${myquery}`);
    window.location = `https://${selectedLearnSystem.domain}${authcodeendpoint}${myquery}`;
  }

    // Create the XHR object.
    createCORSRequest(method, url, async=true) {
      var xhr = new XMLHttpRequest();
      if ("withCredentials" in xhr) {
        // XHR for Chrome/Firefox/Opera/Safari.
        xhr.open(method, url, async);
      } else if (typeof XDomainRequest != "undefined") {
        // https://developer.mozilla.org/en-US/docs/Web/API/XDomainRequest XDomainRequest is Obsolete and Non-standard!
        alert('Unsupported Browser!');
        // XDomainRequest for IE.
        // xhr = new XDomainRequest();
        // xhr.open(method, url);
        // xhr.setRequestHeader('Content-Type', 'text/plain');
        // xhr.responseType = 'text';
      } else {
        // CORS not supported.
        xhr = null;
      }
      return xhr;
    }

  fetchLearnSystems(learnSystem) {
    let responsedata = [
      {"id":"99990125","name":"Bb Biz Dev Smoke Test A","pollingLogin":true,"isForceWebLogin":false,"domain":"partner-smoke-test-a.blackboard.com","allowProspectiveStudentAccess":false,"preferredContactMethods":[0,1],"isMobileSupported":true,"needCheckUserLicense":false,"peopleSoftInstitutionId":"1234523449"},
      {"id":"99990126","name":"Bb Partner A Original","pollingLogin":true,"isForceWebLogin":false,"domain":"bd-partner-a-original.blackboard.com","allowProspectiveStudentAccess":false,"preferredContactMethods":[0,1],"isMobileSupported":true,"needCheckUserLicense":false,"peopleSoftInstitutionId":"1234523449"}
    ];

    const learnSystemsURL = `https://oxi3wh3zrb.execute-api.us-east-1.amazonaws.com/dev/institutions?q=${learnSystem}`;
    const xhr = this.createCORSRequest('GET', learnSystemsURL, false); // does xhr.open async is false
    const thisobj = this;

    if (learnSystem.length > 2){
      xhr.send();
      let theText = xhr.responseText;
      console.log(`theText:${theText}`)
      let theJson = JSON.parse(theText);
      console.log(`theJson:${theJson}`);
      responsedata = responsedata.concat(theJson);
    }

    console.log(`responsedata:${responsedata}`);
    this.setState({ learnSystems: responsedata});
  }

  render() {
    console.log(`SelectLearnSystem render() querystring: ${this.props.querystring}`);
    return (
      <div>
      <Downshift
      onChange={this.downshiftOnChange}
      itemToString={item => (item ? item.name : "")}
    >
      {({
        selectedItem,
        getInputProps,
        getItemProps,
        highlightedIndex,
        isOpen,
        inputValue,
        getLabelProps
      }) => (
        <div>
          <div className="search-container">
            <div className="header-instruction header-generic"
              {...getLabelProps()}
            >
              Institution
            </div>{" "}
            <br />
            <div className="input-wrapper">
              <input className="system-search"
                {...getInputProps({
                  placeholder: "Name of institution",
                  onChange: this.inputOnChange
                })}
              />
            </div>
          </div>
          {isOpen ? (
            <div className="results-container">
              <div className="results-text">
                {this.state.learnSystems
                    .filter(
                        item =>
                            !inputValue ||
                            item.name
                                .toLowerCase()
                                .includes(inputValue.toLowerCase())
                    ).length } results
              </div>
              <div className="dropdown">
              {this.state.learnSystems
                .filter(
                  item =>
                    !inputValue ||
                    item.name
                      .toLowerCase()
                      .includes(inputValue.toLowerCase())
                )
                .slice(0, 10)
                .map((item, index) => (
                  <div
                    className="dropdown-item"
                    {...getItemProps({ key: index, index, item })}
                    style={{
                      backgroundColor:
                        highlightedIndex === index ? "#A234B5" : "#1b1b1b",
                      fontWeight: selectedItem === item ? "bold" : "normal",
                      textDecoration: selectedItem === item ? "underline" : ""
                    }}
                  >
                    {item.name}
                  </div>
                ))}
              </div>
            </div>
          ) : null}
        </div>
      )}
    </Downshift>
    </div>
    );
  }
}

export default SelectLearnSystem
